import {inject} from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import 'whatwg-fetch';
import {Configurations} from 'devtag-aurelia-config-plugin'

@inject(HttpClient, Configurations)
export class ApiClient {
  apiRoot;
  accessToken;

  constructor(httpClient, config) {
    this.apiRoot = config.getConfig('apiRoot');
    this.httpClient = httpClient;
    this.configure();
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
    this.configure();
  }

  clearAccessToken() {
    this.setAccessToken(null);
    this.configure();
  }

  configure() {
    this.httpClient.configure(config => {
      // config.useStandardConfiguration();
      config.withBaseUrl(this.apiRoot);
      config.rejectErrorResponses();
      // Note: when logging out without doing a page load (such as during failed silent renew).
      // The config object already has the old token on it so it is important to clear it.
      let headers = this.accessToken != null
        ? {'Authorization': 'Bearer ' + this.accessToken}
        : {};
      config.withDefaults({headers: headers});
    })
  }

  invoke(className, methodName, args) {
    // Go through arguments object and strip out any File or FileList.
    // These will be sent in separate parts of a multipart http request.
    let [argsWithoutFiles, files] = ApiClient.extractFilesForSideLoading(args);

    let body;
    let argumentsContainsFiles = !files.length;
    // If there are no files then body can contain json directly
    if (argumentsContainsFiles) {
      body = json(argsWithoutFiles);
    }
    // If we have files to upload the body should be a FormData.
    else {
      // Form data
      body = new FormData();

      // Part 1: json
      body.append('json', json(argsWithoutFiles))

      // Part 2-n: files
      files.forEach(part => body.append(part.name, part.value));
    }

    return this.httpClient
      .fetch('/' + className + "/" + methodName,
        {
          method: 'POST',
          body: body
        })

      // Error responses
      .catch(error => {
        if (error instanceof Error) {
          let message;
          if (error.message === 'Failed to fetch') {
            message = "Kunne ikke koble til server."
          } else {
            message = error.message;
          }
          throw new ApiError(message, false);
        } else if (error.headers.get('Content-Type') === 'application/json') {
          // Deserialize because it's a serialized exception from
          // the back end, wrap it in a standardized error type and throw it.
          return error
            .json()
            .then(json => new ApiError(
              json.message,
              true,
              json.throwable,
              json.throwableClassName,
              json.throwableClassSimpleName
            ))
            .then(error => {
              // Note: Even though we can return a promise from a promise handler
              // and expect it to be resolved before the next then() is called,
              // we can't _throw_ a promise and expect the same.
              // See https://stackoverflow.com/a/45890388/5377597
              throw error;
            });
        } else {
          throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
        }
      })

      // Http status 2xx
      .then(response => {
        // 200 OK => response body is json.
          if (response.status === 200) {
          // Deserialize and return the payload. json() returns a promise,
          // but returning a promise from a promise handler works fine.
          return response.json();
        }
        // 204 NO_CONTENT => response body is empty because return type is void.
          else if (response.status === 204) {
          return;
        }

        // Should never get here.
        throw new Error("Only status 200 and 204 are supported OK responses, so this isn't expected.")
      });
  }

  /**
   * @param obj
   * @param keyPath
   * @returns [objWithoutFiles, [{name: '', value: ''}*]
   */
  static extractFilesForSideLoading(obj, keyPath = '') {
    // Stop recursion when we get to a primitive
    let isPrimitive = obj !== Object(obj);
    if (isPrimitive) {
      return [obj, []];
    }

    const objWithoutFiles = {};
    const files = [];

    // Loop through all properties of the given object
    for (const propertyName in obj) {
      if (!obj.hasOwnProperty(propertyName)) {
        continue
      }

      let propertyValue = obj[propertyName];

      let key = keyPath + propertyName;

      // Add each of the multiple files with the same key. This will be grouped in backend and become a list.
      if (propertyValue instanceof FileList) {
        const fileList = propertyValue;
        Array.from(fileList).forEach(file => {
          files.push({name: key, value: file});
        });
      }
      // Add the single file as it's own part.
      else if (propertyValue instanceof File) {
        let file = propertyValue;
        files.push({name: key, value: file});
      }
      //
      else if (Array.isArray(propertyValue)) {
        // Start a new blank array
        objWithoutFiles[propertyName] = []; // Set the new

        // Process each of the array items
        for (const item of propertyValue) {
          let [newItem, newFiles] = ApiClient.extractFilesForSideLoading(item, key + ':');
          objWithoutFiles[propertyName].push(newItem); // Add new item to array
          files.push(...newFiles); // Add the new parts to a flattened list of parts.
        }
      }
      // Recurse other objects
      else {
        let [newPropertyValue, newFiles] = ApiClient.extractFilesForSideLoading(propertyValue, key + ':');
        objWithoutFiles[propertyName] = newPropertyValue; // Set the new
        files.push(...newFiles); // Add the new parts to a flattened list of parts.
      }
    }

    return [objWithoutFiles, files];
  }
}

export class ApiError extends Error {
  isExceptionFromBackend;
  exception;
  exceptionFullClassName;
  exceptionName;

  constructor(message, isExceptionFromBackend, exception, exceptionFullClassName, exceptionName) {
    super(message);
    this.name = this.constructor.name;
    this.isExceptionFromBackend = isExceptionFromBackend;
    this.exception = exception;
    this.exceptionFullClassName = exceptionFullClassName;
    this.exceptionName = exceptionName;
  }

  toString() {
    if (this.isExceptionFromBackend) {
      return "Exception from backend: "
        + this.exceptionFullClassName + "\n"
          + this.message;
    } else {
      return this.message;
    }
  }
}
